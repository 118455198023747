<template>
  <navbar></navbar>
  <div>
    <div>
      <main class="page-content">
        <div class="account-page-area">
        <div class="container-fluid">
            <div class="row">
              <div class="col-lg-3 account_border-right">
                <sideMenuVendor></sideMenuVendor>
              </div>
              <div class="col-lg-9">

                <div class="no-vehicles">
                  <h4>My Vehicles</h4>
                  <a v-if="!items.length" class="btn btn-primary" href="/VehicleUpload"><i class="fa fa-plus-circle"></i>&nbsp;Add Vehicle</a>
                </div>
                 
                <div v-if="!items.length">
                <NotFound></NotFound>

              </div>
                <div v-else
                  class="row"
                >
                
                  <div
                    class="col-lg-4"
                    v-for="VehicleDetails in items"
                    :key="VehicleDetails.vehId"
                    style="margin-bottom: 15px; padding: 0px"
                  >
                  
                    <div>
                      <div class="">
                        <!--new-main-tile-->
                        <section>
                          <div class="" style="margin: 0px 6px">
                            <div class="">
                              <div class="carTileWrap shine-overlay">
                                <div class="shine"></div>
                                <div
                                  class="cwrapImage"
                                  style="position: relative"
                                >
                                  <router-link
                                    :to="{
                                      name: 'EditVehcle',
                                      query: {
                                        vin: VehicleDetails.vin,
                                      },
                                    }"
                                  >
                                    <img v-if="VehicleDetails.vehImage1"
                                      :src=" path + VehicleDetails.vehImage1"
                                      class="img-fluid img-responsive border5px"
                                  /><img v-else
                                      src="../../../public/assets/no-image-icon.png"
                                      class="img-fluid img-responsive border5px"
                                  />
                                
                                </router-link>

                                  <div class="topleft">
                                    <div>
                                      <CountDown
                                        :customstyle="'background-color: beige;'"
                                        :deadline="VehicleDetails.aucExtDate"
                                      ></CountDown>
                                    </div>
                                  </div>
                                  <div class="topright">
                                    <div style="display: flex"></div>
                                  </div>
                                </div>
                                <div>
                                  <div class="vehicleName">
                                    <router-link
                                    :to="{
                                      name: 'VehicleUpload',
                                      query: {
                                        vin: VehicleDetails.vin,
                                      },
                                    }"
                                    >
                                      <h5>
                                        {{ VehicleDetails.brandName }}
                                        {{ VehicleDetails.modelName }}
                                        {{ VehicleDetails.manYear }}
                                      </h5>
                                    </router-link>
                                  </div>
                                  <div>
                                    <!-- <div class="row" style="margin: 0px">
                                      <div class="col-6">
                                        <div>
                                          <i
                                            class="fas fa-tachometer-alt pdtListico"
                                          ></i>
                                          <div class="pdtListicon">
                                            <span>Odometer</span>
                                            <p class="fontWeightbidlist">
                                              {{ VehicleDetails.kmClocked }}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col-6">
                                        <div>
                                          <i
                                            class="fas fa-map-marker pdtListico"
                                          ></i>
                                          <div class="pdtListicon">
                                            <span>Location</span>
                                            <p class="fontWeightbidlist">
                                              {{ VehicleDetails.locName }}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col-6">
                                        <div>
                                          <i class="fas fa-car pdtListico"></i>
                                          <div class="pdtListicon">
                                            <span>Trim Level</span>
                                            <p class="fontWeightbidlist">
                                              Base
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col-6">
                                        <div>
                                          <i
                                            class="fas fa-tachometer-alt pdtListico"
                                          ></i>
                                          <div class="pdtListicon">
                                            <span>Disclosure</span>
                                            <p class="fontWeightbidlist">
                                              Not Available
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col-6">
                                        <div>
                                          <i class="fa fa-fire pdtListico"></i>
                                          <div class="pdtListicon">
                                            <span>Mileage</span>
                                            <p class="fontWeightbidlist">
                                              Not Actual
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="col-6">
                                        <div>
                                          <i
                                            class="fas fa-tachometer-alt pdtListico"
                                          ></i>
                                          <div class="pdtListicon">
                                            <span>As Is</span>
                                            <p class="fontWeightbidlist">
                                              Not Available
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    </div> -->
                                    <hr style="margin: 0px" />
                                    <div class="row">
                                      <div class="col-12">
                                        <div class="text-center">
                                          <div>
                                            <router-link
                                              :to="{
                                                name: 'VehicleUpload',
                                                query: {
                                                  vin: VehicleDetails.vin,
                                                },
                                              }"
                                            >
                                              <button
                                                class="tile-bid-now"
                                                tabindex="-1"
                                              >
                                                View Details
                                              </button>
                                            </router-link>
                                          </div>
                                        </div>
                                      </div>
                                      
                                    </div>

                                    <div></div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <!-- .container -->
                        </section>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-lg-12">
                <div class="uren-paginatoin-area">
                  <div class="row">
                    <div class="col-lg-12">
                      <PageNation
                        :vechiclePage="AuctionsDetails"
                        @pageChange="PageRout"
                      ></PageNation>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </div>
  </div>
</template>
<script>
import NotFound from "@/components/modules/NotFound.vue";
import PageNation from "@/components/modules/PageNation.vue";
import sideMenuVendor from "@/components/sideMenuVendor.vue";
import userService from "@/services/user.service";
import Config from "@/config";
import navbar from "@/components/navbar.vue";
export default {
  components: {
    sideMenuVendor,
    PageNation,
    NotFound,
    navbar
  },
  data() {
    return {
      vechApproval: "",
      sellervechlists: "",
      path: Config.BASE_URL_IMG,

      perPage: 12,
      currentPage: 1,
      AuctionsDetails: [],
      items: [],
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    if (this.loggedIn) {
      this.userinfo = JSON.parse(localStorage.getItem("user"));

      if (typeof this.userinfo.sellerId != "undefined") {
        this.getVehiclesListBySellerId(this.userinfo.sellerId);
      }
    }
  },
  methods: {
    PageRout(userData) {
      // console.log(userData);
      this.items = userData;
    },
    getVehiclesListBySellerId(sellerId) {
      let id = sellerId;
      if (id == "undefind") {
        console.log("undefind");
      } else {
        userService
          .getVehiclesListBySellerId(id)
          .then((response) => {
            this.AuctionsDetails = response.data.Data;
            this.items = this.AuctionsDetails.slice(
              (this.currentPage - 1) * this.perPage,
              this.currentPage * this.perPage
            );
            
          })
          .catch((e) => {
            console.log(e);
          });
      }
    },
  },
};
</script>

